@import url(normalize.css);

html,
body
{
    margin: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    user-select: none;
}

.webgl, .webgl-bg
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    width: 100%;
	height: 100%;
}

.loading-indicator {
    width: 295px;
    height: 100px;
    transform: scale(65%);
    transform-origin: center;
}

.home {
    position: absolute;
    top: -10px;
    left: -50px;
    width: 257px;
    height: 100px;
    transform: scale(30%);
    transform-origin: center;    
    z-index: 10;
}

.huh {
    transform: scale(35%);
}

#svg-l {
    display: block;
}

#svg-s {
    display: none;
}

.about {
    position: absolute;
    width: 185px;
    height: 100px;
    z-index: 11;
    bottom: -20px;
    margin: 0 auto;
    cursor: pointer;
    opacity: 0;
}

.about-modal {
    background-color: #111111;
    color: #ffffff;
    padding: 0 30px 30px 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-container {
    position: absolute;
    display: none;
    flex-direction: column;
    opacity: 0;  
    width: 350px;
    height: auto;
    z-index: 12;
    bottom: 10px;
    margin: 0 auto;
    transform: translateY(110%);
    cursor: pointer;
    font-family: 'Newsreader', serif;
    font-weight: 600;
    text-align: center;
    pointer-events: none;
    /* font-size: 0.8rem; */
}

#secret-tunnels {
    top: 25px;
    bottom: auto;
}

.webring {
    margin-top: 10px;
    padding-top: 30px;
}

.webring-links {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    width: 100%;
}

.calavera {
    margin-left: -25px;
}

p {
    margin-bottom: 10px;
}

.smol {
    font-size: 0.75rem;
    margin-top: 20px;
}

.slm {
    transform: scale(65%);
}

.about-modal a, a:visited {
    color: #ffffff;
}

@media only screen and (max-width: 500px) {
    .home {
        top: auto;
        left: auto;
        bottom: -20px;
        margin: 0 auto;
    }
    #svg-l {
        display: none;
    }
    
    #svg-s {
        display: block;
    }
    .about {
        width: 26px;
        height: 100px;
        z-index: 11;
        /* right: 20px; */
    }
    .huh {
        transform: scale(55%);
    }
}

.f-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
    opacity: 1;
    transform: scale(0.6,0.6);
    z-index: 100;
}